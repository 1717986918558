import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
const Home = lazy(() => import("./pages/Home"));
const ThankYou = lazy(() => import("./pages/ThankYou"));

const App = () => {
  return (
    <BrowserRouter>
      <ToastContainer />
      <Suspense fallback={<div>Loading...</div>}>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/thank-you" element={<ThankYou />} />
        </Routes>
      </Suspense>

    </BrowserRouter>
  );
};

export default App;
